import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/lang/lang.js'
import store from '@/store/index.js'

Vue.use(VueRouter)

function isSystemManage () {
  let systemManage = false
  store.state.userInfo.permissions.forEach(item => {
    if (item.name === 'system-manage') {
      systemManage = true
    }
  })
  return systemManage
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/Cms.vue'),
    redirect: {
      name: 'Dashboard'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/components/Dashboard.vue'),
        meta: {title: '__dashboard'}
      },
      {
        path: 'agent',
        name: 'Agent',
        component: () => import('@/components/Agent.vue'),
        meta: {title: '__agentAccountManagement'},
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'agency',
        name: 'Agency',
        component: () => import('@/components/Agency.vue'),
        meta: {title: '__agencyManagement'}
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/components/User.vue'),
        meta: {title: '__agencyAccountManagement'}
      },
      {
        path: 'device',
        name: 'Device',
        component: () => import('@/components/Device.vue'),
        meta: {title: '__deviceCertificateManagement'}
      },
      {
        path: 'device-control',
        name: 'DeviceControl',
        component: () => import('@/components/DeviceControl.vue'),
        meta: {title: '__deviceManagement'},
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'device-control/history',
        name: 'DeviceControlHistory',
        component: () => import('@/components/DeviceControlHistory.vue'),
        meta: {title: '__deviceCommandRecord'},
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'chiline',
        name: 'Chiline',
        component: () => import('@/components/Chiline.vue'),
        meta: {title: '__chilineManagement'}
      },
      {
        path: 'fora',
        name: 'Fora',
        component: () => import('@/components/Fora.vue'),
        meta: {title: '__foraManagement'}
      },
      // {
      //   path: 'device-report',
      //   name: 'DeviceReport',
      //   component: () => import('@/components/DeviceReport.vue'),
      //   meta: {title: '__errorReport'},
      //   beforeEnter: (to, from, next) => {
      //     store.dispatch('getUserInfo').then(() => {
      //       isSystemManage() ? next() : next({name: 'Error'})
      //     })
      //   }
      // },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('@/components/Contact.vue'),
        meta: {
          title: '__contactUs'
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'announcements',
        name: 'Announcements',
        component: () => import('@/components/announcements/Announcements.vue'),
        meta: {
          title: '__announcements'
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'announcements/add',
        name: 'AnnouncementAdd',
        component: () => import('@/components/announcements/AnnouncementAdd.vue'),
        meta: {
          title: '__announcementAdd'
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'announcements/:id',
        name: 'AnnouncementEdit',
        component: () => import('@/components/announcements/AnnouncementEdit.vue'),
        meta: {
          title: '__announcementEdit'
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
      {
        path: 'chats',
        name: 'Chats',
        component: () => import('@/components/chats/Chats.vue'),
        meta: {
          title: '__chats'
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('getUserInfo').then(() => {
            isSystemManage() ? next() : next({name: 'Error'})
          })
        }
      },
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/Auth.vue'),
    redirect: {
      name: 'Login'
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/components/auth/Login.vue'),
        meta: {title: '__login'}
      },
      {
        path: 'change-password',
        name: 'Change Password',
        component: () => import('@/components/auth/ChangePassword.vue'),
        meta: {title: '__changeYourPassword'}
      }
    ]
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/ErrorPage.vue'),
    meta: {title: '404 Error'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('humetrics_token')
  window.document.title = i18n.t(to.meta.title) + ' | ' + i18n.t('__humetrics')
  document.documentElement.lang = i18n.locale

  if (to.name !== 'Login' && !isAuthenticated) {
    next({name: 'Login'})
  } else if (to.name === 'Login' && isAuthenticated) {
    next({name: 'Dashboard'})
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  if (Number(store.state.viewport) < 992 && !store.state.isHideNav) {
    store.commit('HidedNav')
  } else if (Number(store.state.viewport) > 992 && store.state.isHideNav) {
    store.commit('HidedNav')
  }
})
export default router
