import { axiosConfig } from '../api/axios-config.js'

export const auth = {
  // 登入
  login: data => axiosConfig.post('/api/login', data),

  // 變更密碼
  changePassword: (data, token) => axiosConfig.post('/api/account', data, token),

  // 取得使用者資訊
  getUser: token => axiosConfig.get('/api/account', token),

  // 取得使用者資訊
  updateUser: (data, token) => axiosConfig.patch('/api/account', data, token)
}
