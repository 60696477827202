<template>
  <div id="app">
    <router-view />
    <Loading :show="isLoading"/>
    <Alerts :show="isAlerting"/>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Alerts from '@/components/Alerts.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'app',
  components: { Loading, Alerts },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapState(['isLoading', 'isAlerting'])
  },
  created () {
    this.getToken()
  },
  destroyed () {
    window.removeEventListener('resize', this.detectWindowWidth)
  },
  methods: {
    ...mapMutations(['setWindowWidth', 'getViewport', 'Loading', 'Loaded', 'getToken']),
    debounce (fn, delay = 800) {
      // let timer
      const vm = this
      // 返回一個函式，這個函式會在一個時間區間結束後的 delay 毫秒時執行 func 函式
      return function () {
        // 儲存函式呼叫時的上下文和引數，傳遞給func
        const context = this
        const args = arguments

        // 函式被呼叫，清除定時器
        // clearTimeout(timer)

        // 當返回的函式被最後一次呼叫後（也就是使用者停止了某個連續的操作），
        // 再過 delay 毫秒就執行 func
        // 只有寬度變化才繼續執行 func
        if (vm.windowWidth === window.innerWidth || Math.abs(window.innerWidth - vm.windowWidth) < 100) return
        vm.windowWidth = window.innerWidth
        vm.Loading()
        setTimeout(function () {
          fn.apply(context, args)
          vm.Loaded()
        }, delay)
      }
    },
    detectWindowWidth () {
      this.setWindowWidth(window.innerWidth)
    },
    initialize () {
      this.detectWindowWidth()
    }
  },
  mounted () {
    const vm = this
    vm.initialize()
    vm.getViewport()
    window.addEventListener('resize', vm.debounce(() => {
      vm.initialize()
      vm.getViewport()
    }), 300)
  }
}
</script>
